export function useWindowSize() {
  const [windowSize, setWindowSize] = React.useState({
    width: null,
    height: null,
  })

  React.useEffect(
    () => {
      handleResize()

      window.addEventListener('resize', handleResize)

      return () => {
        window.removeEventListener('resize', handleResize)
      }

      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        })
      }
    },
    []
  )

  return windowSize
}
