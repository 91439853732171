import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas-lite'
import { useElementSize } from '@kaliber/use-element-size'
import { useMediaQuery } from '@kaliber/use-media-query'
import { useTranslate } from '/machinery/I18n'
import { useUrls } from '/domain/useUrls'
import { animated } from 'react-spring'

import { HeroVideo, useAnimations } from '/features/pageOnly/Hero'
import { Button } from '/features/buildingBlocks/Button'
import { Icon } from '/features/buildingBlocks/Icon'

import mediaStyles from '/cssGlobal/media.css'
import styles from './Hero.css'

import magnifyingGlassIcon from '/images/icons/magnifying-glass.raw.svg'
import { useWindowSize } from '/machinery/useWindowSize'

export function Hero({ video, image = undefined }) {
  return <HeroBase {...{ video, image }} ButtonComponent={Buttons} />
}

export function HeroRetailOnly({ video, image = undefined }) {
  return <HeroBase {...{ video, image }} ButtonComponent={RetailOnlyButton} />
}

function HeroBase({ video, ButtonComponent, image = undefined }) {
  const { animations, startAnimating } = useAnimations()
  const [subtitleAnimation, titleAnimation, buttonContaineranimation] = animations

  return (
    <HeroVideo onComponentReady={startAnimating} {...{ video, image }}>
      <div className={styles.componentBase}>
        <div className={cx(styles.typography, styles.typographyLayout)} >
          <Subtitle animation={subtitleAnimation} />
          <Title animation={titleAnimation} layoutClassName={styles.titleLayout} />
        </div>

        <ButtonComponent layoutClassname={styles.buttonsLayout} animation={buttonContaineranimation} />
      </div>
    </HeroVideo>
  )
}

function Subtitle({ animation }) {
  return <animated.p style={animation}>Work with</animated.p>
}

function Title({ animation, layoutClassName }) {
  const { ref: sizeRef, size: { width } } = useElementSize()
  const { height: windowHeight } = useWindowSize()

  const breakpointMd = parseFloat(mediaStyles.breakpointMd)
  const riveFileAspectRatio = 1.61

  const isNarrowerThanMd = width <= breakpointMd
  const hasLimitedHeight = windowHeight < 950
  const hasSmallHeight = windowHeight < 680 // with h=300, at 680 next section starts overlapping searchbox so we need extra case

  const height = isNarrowerThanMd && hasSmallHeight
    ? Math.min(160, width / riveFileAspectRatio)
    : isNarrowerThanMd && hasLimitedHeight
      ? Math.min(300, width / riveFileAspectRatio)
      : Math.min(breakpointMd, width) / riveFileAspectRatio

  const { RiveComponent } = useRive({
    src: '/static/heartAndSoul.riv',
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.BottomCenter
    }),
  })

  return (
    <animated.div ref={sizeRef} style={animation} className={cx(styles.componentTitle, layoutClassName)}>
      <RiveComponent style={{ width, height }} />
    </animated.div>
  )
}

function Buttons({ animation, layoutClassName }) {
  const urls = useUrls()

  return (
    <ButtonsBase {...{ animation, layoutClassName }}>
      <>
        <JobsButton
          type='shop'
          url={urls.jobOverview.shops()}
          layoutClassName={styles.shopButtonLayout}
        />
        <JobsButton
          type='office'
          url={urls.jobOverview.offices()}
          layoutClassName={styles.officeButtonLayout}
        />
      </>
    </ButtonsBase>
  )
}

function RetailOnlyButton({ animation, layoutClassName }) {
  const urls = useUrls()

  return (
    <ButtonsBase retailOnly {...{ animation, layoutClassName }}>
      <JobsButton
        type='shop'
        url={urls.jobOverview.shops()}
        layoutClassName={styles.shopButtonLayout}
      />
    </ButtonsBase>
  )
}

function ButtonsBase({ animation, layoutClassName, children, retailOnly = undefined }) {
  return (
    <animated.div
      className={cx(styles.componentButtonsBase, retailOnly && styles.retailOnly, layoutClassName)}
      style={animation}
    >
      {children}
      <HomeSearchInput layoutClassName={styles.searchInputLayout} />
    </animated.div>
  )
}

function JobsButton({ type, url, layoutClassName }) {
  const isMobile = useMediaQuery(mediaStyles.viewportMd) === false
  const { __ } = useTranslate()

  return (
    <Button href={url} dataX='link-to-jobsOverview' {...{ layoutClassName }}>
      {isMobile ? __`home-hero-${type}-careers` : __`home-hero-find-${type}-careers` }
    </Button>
  )
}

function HomeSearchInput({ layoutClassName }) {
  const { __ } = useTranslate()
  const urls = useUrls()

  return (
    <form action={urls.jobOverview.all()} className={cx(styles.componentHomeSearchInput, layoutClassName)}>
      <Icon icon={magnifyingGlassIcon} layoutClassName={styles.iconLayout} />
      <input
        type='text'
        name='textQuery'
        aria-label={__`label-search`}
        placeholder={__`component-Hero-im-looking-for`}
        className={styles.searchInputElement}
      />

      <button data-x='click-to-search' type='submit' className={styles.searchButtonElement}>
        <span className={styles.text}>{__`component-Hero-search`}</span>
        <span className={styles.icon}>
          <Icon icon={magnifyingGlassIcon} layoutClassName={styles.iconLayout} />
        </span>
      </button>
    </form>
  )
}
