import { useLocationMatch } from '@kaliber/routing'
import { appendQueryString } from '/machinery/appendQueryString'
import { routeMap } from '/routeMap'

export function useUrls() {
  const { params: { language } } = useLocationMatch()
  const jobsOverview = routeMap.app.jobs.overview({ language })

  return {
    jobOverview: {
      all: (queryString = undefined) =>
        appendQueryString(jobsOverview, queryString),
      shops: (queryString = undefined) =>
        appendQueryString(jobsOverview, { locationType: 'shops', ...queryString }),
      offices: (queryString = undefined) =>
        appendQueryString(jobsOverview, { locationType: 'offices', ...queryString }),
    }
  }
}
